'use client';
import { ContactButton } from '@components/features/contact-button/contact-button';
import { ContactPopup } from '@components/features/contact-popup/contact-popup';
import {
    MobileHeader,
    type MobileMenuState,
} from '@components/features/header/features/mobile-header/mobile-header';
import { HEADER_ITEMS } from '@constants/header-items';
import { PAGE_PATH } from '@constants/page';
import {
    MenuHamburger24Icon,
    Message24Icon,
} from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { RegularButtonText } from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import Image from 'next/image';
import Link from 'next/link';
import { type FC, useCallback, useState } from 'react';
import css from './header.module.scss';

export const Header: FC = () => {
    const [mobileMenuState, setMobileMenuState] =
        useState<MobileMenuState>('closed');
    const [modalState, setModalState] = useState(false);

    const openMobileMenu = () => {
        setMobileMenuState('opening');
        setTimeout(() => setMobileMenuState('open'), 300);
    };

    const switchModal = () => {
        setModalState(!modalState);
    };

    const handleSetMobileMenuState = useCallback((state: MobileMenuState) => {
        setMobileMenuState(state);
    }, []);

    return (
        <>
            <nav className={css.root}>
                <Link href={PAGE_PATH} className={css.logoWrapper}>
                    <Image
                        src="/img/logo.png"
                        width={42}
                        height={52}
                        alt="Logo"
                    />
                    <Typography variant="heading-1">AH Labs</Typography>
                </Link>

                <button
                    className={css.mobileNavButton}
                    type="button"
                    onClick={openMobileMenu}
                >
                    <MenuHamburger24Icon />
                </button>

                <ul className={css.links}>
                    {HEADER_ITEMS.links.map(item => (
                        <li key={item.label}>
                            <Link
                                href={item.url}
                                title={`Ga naar ${item.label}`}
                            >
                                <Typography>{item.label}</Typography>
                            </Link>
                        </li>
                    ))}

                    <li>
                        <ContactButton>
                            <RegularButtonText>Get in touch</RegularButtonText>
                            <Message24Icon />
                        </ContactButton>
                    </li>
                </ul>
            </nav>

            <ContactPopup isOpen={modalState} setOpenAction={switchModal} />

            {mobileMenuState !== 'closed' && (
                <MobileHeader
                    mobileMenuState={mobileMenuState}
                    setMobileMenuAction={handleSetMobileMenuState}
                />
            )}
        </>
    );
};
