import {
    INTERNSHIP_PATH,
    PAGE_PATH,
    WAY_OF_WORKING_PATH,
} from '@constants/page';
import { Message24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import type React from 'react';

export interface HeaderItem {
    label: string;
    url: string;
    icon?: React.ReactElement;
}

export interface HeaderLinks {
    links: HeaderItem[];
    cta: HeaderItem;
}

export const HEADER_ITEMS: HeaderLinks = {
    links: [
        {
            label: 'Home',
            url: PAGE_PATH,
        },
        {
            label: 'Onze werkwijze',
            url: WAY_OF_WORKING_PATH,
        },
        {
            label: 'Stage bij Labs',
            url: INTERNSHIP_PATH,
        },
    ],
    cta: {
        label: 'Get in touch',
        url: '',
        icon: <Message24Icon />,
    },
};
