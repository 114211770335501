'use client';

import { HEADER_ITEMS } from '@constants/header-items';
import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import Link from 'next/link';
import { type FC, useEffect } from 'react';
import css from './mobile-header.module.scss';

export declare type MobileMenuState = 'closed' | 'closing' | 'opening' | 'open';

interface MobileHeaderProps {
    mobileMenuState: MobileMenuState;
    setMobileMenuAction: (state: MobileMenuState) => void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({
    mobileMenuState,
    setMobileMenuAction,
}) => {
    const closeMobileMenu = () => {
        setMobileMenuAction('closing');
        setTimeout(() => setMobileMenuAction('closed'), 300);
    };

    useEffect(() => {
        const handleResize = () => {
            console.log(mobileMenuState);
            if (
                (mobileMenuState === 'open' || mobileMenuState === 'opening') &&
                window.innerWidth > 768
            ) {
                closeMobileMenu();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <div
            className={classNames(
                css.mobileOverlay,
                mobileMenuState === 'closing' && css.closing,
            )}
        >
            <button
                className={css.mobileClose}
                type="button"
                onClick={closeMobileMenu}
            >
                <Close24Icon />
            </button>

            <ul className={css.mobileLinks}>
                {HEADER_ITEMS.links.map(item => (
                    <li key={item.label}>
                        <Link href={item.url} title={`Ga naar ${item.label}`}>
                            <Typography>{item.label}</Typography>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
