'use client';

import { ContactPopup } from '@components/features/contact-popup/contact-popup';
import { RegularButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { type FC, type PropsWithChildren, useState } from 'react';

export const ContactButton: FC<PropsWithChildren> = ({ children }) => {
    const [modalState, setModalState] = useState(false);

    const switchModal = () => {
        setModalState(!modalState);
    };

    return (
        <>
            <RegularButton onClick={() => setModalState(true)}>
                {children}
            </RegularButton>

            <ContactPopup isOpen={modalState} setOpenAction={switchModal} />
        </>
    );
};
