import(/* webpackMode: "eager", webpackExports: ["ContactButton"] */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/contact-button/contact-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Reference"] */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/footer/reference/reference.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/header/header.tsx");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/node_modules/@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography.module.css");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/node_modules/@royalaholddelhaize/design-system-pantry-web/components/experimental/spinner/spinner.module.css");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/node_modules/@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button.module.css");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/apps/labs-portfolio/src/components/features/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/__w/labs-venture/labs-venture/node_modules/next/dist/client/image-component.js");
