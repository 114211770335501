'use client';

import css from './reference.module.scss';

import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, PropsWithChildren } from 'react';

export interface ReferenceProps {
    uri: string;
}

export const Reference: FC<PropsWithChildren<ReferenceProps>> = ({
    uri,
    children,
}) => {
    return (
        <>
            <Typography
                onClick={() => {
                    window.location.href = uri;
                }}
                className={css.reference}
            >
                {children}
            </Typography>
        </>
    );
};
